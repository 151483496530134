import React, { useState, useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import ReactECharts from 'echarts-for-react';
import { Container, Row, Col } from 'react-bootstrap';
//import { getPowerMons } from '../netmon/netmon.state';
import { use } from 'echarts';
import { set } from 'react-ga';
import { getPowerMons } from '../netmon/netmonSlice';

const PowerMonChartBar = ({ selectedData,startdays }) => {
  const dispatch = useDispatch();
  const { powerMonitors} = useSelector((store) => store.netmon);
  const [categoryArray, setCategory] = useState([]);
  const [data1, setData1] = useState([]);
  
  const [power, setPower] = useState([]);

  const fetchData = async () => {
    
    console.log('fetching data');
    //getPowerMons();
    let tcat = [];
    let data1 = [];
    //setPower(await getPowerMons());
    console.log(power);
    for (let m of powerMonitors) {
      data1.push(Number(m[selectedData]).toFixed(2)); // use selectedData to access the property
      let dt = new Date(Math.floor(m.timestamp));
      tcat.push(
        dt.toLocaleString('en-us', {
          minute: 'numeric',
          hour12: true,
          hour: 'numeric',
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        })
      );
    }
    setData1(data1);
    setCategory(tcat);
  };

  
useEffect(() => {
  fetchData();
}, [powerMonitors]);
  
   useEffect(() => {
    
    fetchData();
  }, []); 
  //onclick event for setting start days
  
  const eChartsOption = {
    textStyle: {
      color: '#fff'
    },
    xAxis: {
      type: 'category',
      data: categoryArray,
      axisLabel: {
        opacity: 0.5 // set the opacity of the axis labels to 50%
      }
    },
    yAxis: {
      type: 'value'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    dataZoom: [
      {
        type: 'slider',
        start: 0,
        end: 100
      },
      {
        type: 'inside',
        start: 0,
        end: 100
      }
    ],
    series: [
      {
        name: selectedData,
        type: 'bar',
        data: data1,
        itemStyle: {
          color: 'green' // replace with your color
        }
      }
    ]
  };

  return (
    <Container style={{ marginTop: '0px' }}>
    <ReactECharts option={eChartsOption} />
      </Container>
  );
};

export default PowerMonChartBar;
