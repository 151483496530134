import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const RelaySettingsModal = ({ showModal, setShowModal, relaySettings, handleInputChange, handleSave }) => {
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton style={{ backgroundColor: 'black', color: 'white' }}>
        <Modal.Title style={{ color: 'white' }}>Relay Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: 'black', color: 'white' }}>
        <Form>
          <Form.Group controlId="formPowerOn" style={{ display: 'flex', alignItems: 'center' }}>
            <Form.Label style={{ color: 'white', marginRight: '10px' }}>Power On</Form.Label>
            <div style={{ position: 'relative' }}>
              <input
                type="checkbox"
                name="power_on"
                checked={relaySettings.power_on}
                onChange={(e) => handleInputChange({ target: { name: 'power_on', value: e.target.checked } })}
                style={{
                  width: '40px',
                  height: '20px',
                  appearance: 'none',
                  backgroundColor: relaySettings.power_on ? '#4caf50' : '#ccc',
                  borderRadius: '20px',
                  position: 'relative',
                  outline: 'none',
                  cursor: 'pointer',
                  transition: 'background-color 0.2s'
                }}
              />
              <span
                style={{
                  content: '',
                  position: 'absolute',
                  width: '16px',
                  height: '16px',
                  borderRadius: '50%',
                  top: '2px',
                  left: relaySettings.power_on ? '22px' : '2px',
                  backgroundColor: 'white',
                  transition: 'left 0.2s'
                }}
              />
            </div>
          </Form.Group>
          <Form.Group controlId="formState">
            <Form.Label style={{ color: 'white' }}>State</Form.Label>
            <Form.Control
              as="select"
              name="state"
              value={relaySettings.state}
              onChange={handleInputChange}
              style={{ backgroundColor: 'black', color: 'white', border: '1px solid white' }}
            >
              <option value="manual">Manual</option>
              <option value="auto">Auto</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formOnTemp">
            <Form.Label style={{ color: 'white' }}>On Temperature</Form.Label>
            <Form.Control
              type="number"
              name="on_temp"
              value={relaySettings.on_temp}
              onChange={handleInputChange}
              style={{ backgroundColor: 'black', color: 'white', border: '1px solid white' }}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: 'black' }}>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RelaySettingsModal;
