import React from "react";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { useNavigate } from "react-router-dom";
import axios from 'axios';




const baseUrl = process.env.REACT_APP_NETMONSERVER + '/netmon';

const initialState = {
    devices: [],
    device: {},
    deviceChanged: false,
    powerMonitors: [],
    powerMonDevices: [],
    selectedPowerMonDevice: {},
    p2pSpeeds: [],
    p2pConfigs: []
};
export const setRelay = createAsyncThunk(
    'netmon/setRelay',
    async (data) => {
        try {
            console.log('getDevices');
            const response = await axios.post(baseUrl + '/setRelay',data);
            return response.data;
        } catch (error) {
            console.error(error);
            return [];
        }
    }
);
export const getDevice = createAsyncThunk(
    'netmon/getDevice',
    async (data) => {
        try {
            console.log('getDevice');
            const response = await axios.post(baseUrl + '/getPowerMonDevice',data);
            return response.data;
        } catch (error) {
            console.error(error);
            return [];
        }
    }
);
export const getDevices = createAsyncThunk(
    'netmon/getDevices',
    async () => {
        try {
            console.log('getDevices');
            const response = await axios.post(baseUrl + '/getDevices');
            return response.data;
        } catch (error) {
            console.error(error);
            return [];
        }
    }
);

export const getPowerMonDevices = createAsyncThunk(
    'netmon/getPowerMonDevices',
    async () => {
        try {
            console.log('getPowerMonDevices');
            const response = await axios.post(baseUrl + '/getPowerMonDevices');
            return response.data;
        } catch (error) {
            console.error(error);
            return [];
        }
    }
);

export const getPowerMons = createAsyncThunk(
    'netmon/getPowerMons',
    async (data) => {
        try {
            console.log('getPowerMons device',data.device,'duration',data.duration,'baseUrl',baseUrl + '/getPowerMons');
            const response = await axios.post(baseUrl + '/getPowerMons',data);
            return response.data;
        } catch (error) {
            console.error(error);
            return [];
        }
    }
);

export const getP2PConfigs = createAsyncThunk(
    'netmon/getP2PConfigs',
    async (deviceName) => {
        try {
            console.log('getP2PConfigs');
            const response = await axios.post(baseUrl + '/getP2pSpeedConfig', { deviceName });
            return response.data;
        } catch (error) {
            console.error(error);
            return [];
        }
    }
);

const netmonSlice = createSlice({
    name: 'netmon',
    initialState,
    reducers: {
        setDevice: (state, action) => {
            state.device = action.payload;
        },
        setSelectedPowerMonDevice: (state, action) => {
            state.selectedPowerMonDevice = action.payload;
        },
        setP2PConfigs: (state, action) => {
            state.p2pConfigs = action.payload;
        },
        setDeviceChanged: (state, action) => {
            state.deviceChanged = action.payload;
        }

    },
    extraReducers: {
        [getDevices.fulfilled]: (state, action) => {
            state.devices = action.payload;
        },
        [getPowerMonDevices.fulfilled]: (state, action) => {
            state.powerMonDevices = action.payload;
        },
        [getPowerMons.fulfilled]: (state, action) => {
            state.powerMonitors = action.payload;
        },
        [getP2PConfigs.fulfilled]: (state, action) => {
            state.p2pConfigs = action.payload;
        },
        [getDevice.pending]: (state) => {
            state.deviceChanged = false;
        },
        [getDevice.fulfilled]: (state, action) => {
            state.selectedPowerMonDevice = action.payload;
            if (!state.deviceChanged) {
            
            state.deviceChanged = true;
            }
        }
    }
});

export const { setDevice,setDeviceChanged, setSelectedPowerMonDevice, setP2PConfigs } = netmonSlice.actions;

export default netmonSlice.reducer;