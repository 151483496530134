import React from "react";
import { BrowserRouter, BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./features/home/Home";
import HomePage from "./features/home/homePage";
import Login from "./features/user/Login";
import Logout from "./features/user/logout";

import P2PConfigs from "./features/netmon/p2pConfig";
import CurrentPowerOverview from "./features/dashboard/currentPowerOverview";


import UserList from "./features/user/userList";
import EditUser from "./features/user/editUser";
import RegisterUser from "./features/user/registerUser";
import DocHome from "./features/documentation/docHome";




import { useMediaQuery } from 'react-responsive'


function App() {
  
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1224px)' })
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1224px)' })
  return (
    <BrowserRouter>
      <Routes>
          <Route path={"/"} element={<Home />} />
          <Route exact path={"/login"} element={<Login />} />
        
          <Route exact path={"/users"} element={<UserList />} />
          <Route exact path={"/editUser"} element={<EditUser />} />
          <Route exact path={"/registerUser"} element={<RegisterUser />} />
          <Route exact path={"/logout"} element={<Logout />} />
          
          
          <Route exact path={"/homepage"} element={<HomePage />} />
          <Route exact path="/relaystations" element={<CurrentPowerOverview />} />
          <Route exact path="/docs" element={<DocHome />} />

          <Route exact path={"/p2pConfigs"} element={<P2PConfigs />} />

         
        </Routes>
    </BrowserRouter>
  );
}

export default App;
