import React, { useEffect } from 'react';
import { Row, Col, } from 'react-bootstrap';

import CurrentPowerOverview from './currentPowerOverview';


const Dashboard = () => {
    return (
        <div>
            <h1>Dashboard</h1>
        </div>
    );
    };

export default Dashboard;