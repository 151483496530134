import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Row, Col,Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getPowerMonDevices,setSelectedPowerMonDevice } from '../netmon/netmonSlice';
import PowerMonChart from './powerMonChart';
import BatteryGauge from 'react-battery-gauge';
import GaugeChart from 'react-gauge-chart';
import { text } from '@fortawesome/fontawesome-svg-core';
import GaugeComponent from 'react-gauge-component';
import PowerDeviceDetail from './powerDeviceDetail';
import { use } from 'echarts';
import Header from '../../Header';
import { ReactComponent as FanOn } from '../../icons/fan-on.svg';
import { ReactComponent as FanOff } from '../../icons/fan-off.svg';

const CurrentPowerOverview = () => {
  const dispatch = useDispatch();
  const { powerMonDevices } = useSelector(store => store.netmon);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    dispatch(getPowerMonDevices());
    const id = setInterval(() => {
      if (!selectedDevice)
        dispatch(getPowerMonDevices());
    }, 5000); 
    setIntervalId(id);

    return  () => clearInterval(id);
  }, [dispatch]);
  useEffect(() => {
    console.log('selectedDevice', selectedDevice);
   
  }, [selectedDevice]);

  const handleRowClick = (device) => {
    console.log('handleRowClick', device);
    dispatch(setSelectedPowerMonDevice(device));
    setSelectedDevice(device);
     
  };

  return (
    <div>
      <Header />
      {selectedDevice ? (<div>
        <Button onClick={() => setSelectedDevice(null)}>Close</Button>
        <PowerDeviceDetail seldevice={selectedDevice} onClose={() => setSelectedDevice(null)} /></div>
      ) : (
        powerMonDevices.map((device, index) => (
          <div key={index} onClick={() => handleRowClick(device)}>
            <Row>
              <Col className="col-md-2">
                <h6>{device.name}</h6>
              </Col>
              <Col className="col-md-3">
                <h6>{device.description}</h6>
              </Col>
              
              <Col className="col-md-1">
                <div style={{ textAlign: 'center' }}>
                  <GaugeComponent
                    type="semicircle"
                    arc={{
                      width: 0.2,
                      padding: 0.005,
                      cornerRadius: 1,
                      subArcs: [
                        {
                          limit: 80,
                          color: 'red',
                          showTick: true,
                          tooltip: {
                            text: 'Battery Too Low'
                          }
                        },
                        {
                          limit: 90,
                          color: '#F5CD19',
                          showTick: true,
                          tooltip: {
                            text: 'Warning: Low Battery'
                          }
                        },
                        {
                          color: 'green',
                          tooltip: {
                            text: 'Battery OK'
                          }
                        }
                      ]
                    }}
                    pointer={{
                      color: '#345243',
                      length: 0.8,
                      width: 15
                    }}
                    labels={{
                      valueLabel: { formatTextValue: value => value + '%' },
                      tickLabels: {
                        type: 'outer',
                        valueConfig: { formatTextValue: value => value + '%', fontSize: 10 },
                        ticks: [
                          { value: 60 },
                          { value: 50 }
                        ]
                      }
                    }}
                    value={device.powerMonCurrent.battery_percentage}
                    minValue={40}
                    maxValue={100}
                  />
                  <h8 style={{ textAlign: 'center' }}>Battery %</h8>
                </div>
              </Col>
              <Col className="col-md-1">
                <div style={{ textAlign: 'center' }}>
                  <GaugeComponent
                    type="semicircle"
                    arc={{
                      width: 0.2,
                      padding: 0.005,
                      cornerRadius: 1,
                      subArcs: [
                        {
                          limit: 10,
                          color: 'red',
                          showTick: true,
                          tooltip: {
                            text: 'Voltage Too Low'
                          }
                        },
                        {
                          limit: 12,
                          color: '#F5CD19',
                          showTick: true,
                          tooltip: {
                            text: 'Warning: Low Voltage'
                          }
                        },
                        {
                          color: 'green',
                          tooltip: {
                            text: 'Voltage OK'
                          }
                        }
                      ]
                    }}
                    pointer={{
                      color: '#345243',
                      length: 0.8,
                      width: 15
                    }}
                    labels={{
                      valueLabel: { formatTextValue: value => value + ' Volts' },
                      tickLabels: {
                        type: 'outer',
                        valueConfig: { formatTextValue: value => value + ' Volts', fontSize: 10 },
                        ticks: [
                          { value: 13.2 },
                          { value: 14.8 }
                        ]
                      }
                    }}
                    value={device.powerMonCurrent.battery_voltage}
                    minValue={8}
                    maxValue={16}
                  />
                  <h8 style={{ textAlign: 'center' }}>Battery Volts</h8>
                </div>
              </Col>
              <Col className="col-md-1">
                <div style={{ textAlign: 'center' }}>
                  <GaugeComponent
                    type="semicircle"
                    arc={{
                      width: 0.2,
                      padding: 0.005,
                      cornerRadius: 1,
                      subArcs: [
                        {
                          color: 'blue',
                          tooltip: {
                            text: 'Current Generation in Amps'
                          }
                        }
                      ]
                    }}
                    pointer={{
                      color: '#345243',
                      length: 0.8,
                      width: 15
                    }}
                    labels={{
                      valueLabel: { formatTextValue: value => value + ' Amps' },
                      tickLabels: {
                        type: 'outer',
                        valueConfig: { formatTextValue: value => value + ' Amps', fontSize: 10 },
                        ticks: [
                          { value: 5 },
                          { value: 10 },
                          { value: 15 }
                        ]
                      }
                    }}
                    value={device.powerMonCurrent.battery_current}
                    minValue={0}
                    maxValue={20}
                  />
                  <h6>Solar Current</h6>
                </div>
              </Col>
              <Col className="col-md-1">
                <div style={{ textAlign: 'center' }}>
                  <GaugeComponent
                    type="semicircle"
                    arc={{
                      width: 0.2,
                      padding: 0.005,
                      cornerRadius: 1,
                      subArcs: [
                        {
                          color: 'lightgreen',
                          tooltip: {
                            text: 'Voltage OK'
                          }
                        }
                      ]
                    }}
                    pointer={{
                      color: '#345243',
                      length: 0.8,
                      width: 15
                    }}
                    labels={{
                      valueLabel: { formatTextValue: value => value + ' Amp/hour' },
                      tickLabels: {
                        type: 'outer',
                        valueConfig: { formatTextValue: value => value + ' Amp/hour', fontSize: 10 },
                        ticks: [
                          { value: 20 },
                          { value: 40 },
                          { value: 60 },
                          { value: 80 },
                          { value: 100 }
                        ]
                      }
                    }}
                    value={device.powerMonCurrent.charging_amp_hours_today}
                    minValue={0}
                    maxValue={120}
                  />
                  <h8 style={{ textAlign: 'center' }}>Amp/hours</h8>
                </div>
              </Col>
              <Col className="col-md-1">
                <div style={{ textAlign: 'center' }}>
                  <GaugeComponent
                    type="semicircle"
                    arc={{
                      width: 0.2,
                      padding: 0.005,
                      cornerRadius: 1,
                      subArcs: [
                        {
                          limit: 95,
                          color: 'Green',
                          showTick: true,
                          tooltip: {
                            text: 'Temperature OK'
                          }
                        },
                        {
                          limit: 110,
                          color: 'yellow',
                          showTick: true,
                          tooltip: {
                            text: 'Warning: Temperature High'
                          }
                        },
                        {
                          color: 'red',
                          tooltip: {
                            text: 'Temperature Too High'
                          }
                        }
                      ]
                    }}
                    pointer={{
                      color: '#345243',
                      length: 0.8,
                      width: 15
                    }}
                    labels={{
                      valueLabel: { formatTextValue: value => value + ' ºF' },
                      tickLabels: {
                        type: 'outer',
                        valueConfig: { formatTextValue: value => value + ' ºF', fontSize: 10 },
                        ticks: [
                          { value: 50 },
                          { value: 70 }
                        ]
                      }
                    }}
                    value={device.powerMonCurrent.controller_temperature}
                    minValue={20}
                    maxValue={130}
                  />
                  <h8 style={{ textAlign: 'center' }}>Controller Temp</h8>
                </div>
              </Col>
              <Col className="col-md-1">
                <div style={{ textAlign: 'center' }}>
                  <GaugeComponent
                    type="semicircle"
                    arc={{
                      width: 0.2,
                      padding: 0.005,
                      cornerRadius: 1,
                      subArcs: [
                        {
                          limit: 95,
                          color: 'Green',
                          showTick: true,
                          tooltip: {
                            text: 'Temperature OK'
                          }
                        },
                        {
                          limit: 110,
                          color: 'yellow',
                          showTick: true,
                          tooltip: {
                            text: 'Warning: Temperature High'
                          }
                        },
                        {
                          color: 'red',
                          tooltip: {
                            text: 'Temperature Too High'
                          }
                        }
                      ]
                    }}
                    pointer={{
                      color: '#345243',
                      length: 0.8,
                      width: 15
                    }}
                    labels={{
                      valueLabel: { formatTextValue: value => value + ' ºF' },
                      tickLabels: {
                        type: 'outer',
                        valueConfig: { formatTextValue: value => value + ' ºF', fontSize: 10 },
                        ticks: [
                          { value: 50 },
                          { value: 70 }
                        ]
                      }
                    }}
                    value={device.cabinet_temperature}
                    minValue={20}
                    maxValue={130}
                  />
                  <h8 style={{ textAlign: 'center' }}>Cabinet Temp</h8>
                </div>
              </Col>
              <Col className="col-md-1">
              <div style={{margin:"10px",width: 80, height: 80,textAlign:"center"}}>
                  { device.relay1.power_on === true ? <div><FanOn /><h6>Fan On</h6></div> : <div><FanOff /><h6>Fan Off</h6></div> }
                   
                </div>
                </Col>
            </Row>
            <Row></Row>
          </div>
        ))
      )}
    </div>
  );
};

export default CurrentPowerOverview;
