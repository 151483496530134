import React from 'react';
//import NetworkIcon from '../../icons/network_button.svg';
import { ReactComponent as NetworkIcon } from '../../icons/network-wired-solid.svg';
import { ReactComponent as ElectricalIcon } from '../../icons/bolt-lightning-solid.svg';

import Header from '../../Header';

const DocHome = () => {
    return (
        <div>
            <Header/>
            <div style={{margin:"10px",width: 100, height: 100,textAlign:"center"}}>
            <div style={{padding:"5px",  border: '1px solid lightblue', borderRadius: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <NetworkIcon />
   
            </div>
            <h6>Network</h6>
            </div>
            <div style={{margin:"10px",width: 100, height: 100,textAlign:"center"}}>
            <div style={{padding:"5px",  border: '1px solid lightblue', borderRadius: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <ElectricalIcon />
                
            </div>
            <h6>Electrical</h6>
            </div>
        </div>
    );
};

export default DocHome;