import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/user/userSlice';
import modalReducer from '../features/modal/modalSlice';
import netmonReducer from '../features/netmon/netmonSlice';

export * from '../features/user/userSlice';
export * from '../features/netmon/netmonSlice';
export * from '../features/modal/modalSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    netmon : netmonReducer,
    modal: modalReducer,
  },
});

