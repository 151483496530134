import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, CardHeader, CardBody } from 'react-bootstrap';
import GaugeComponent from 'react-gauge-component';
import { ReactComponent as FanOn } from '../../icons/fan-on.svg';
import { ReactComponent as FanOff } from '../../icons/fan-off.svg';
import { getPowerMons, setRelay, getDevice, setDeviceChanged } from '../netmon/netmonSlice';
import PowerMonChart from './powerMonChartBar';
import RelaySettingsModal from './relaySettingsModal';
import { use } from 'echarts';

const PowerDeviceDetail = ({ seldevice }) => {
  const dispatch = useDispatch();
  const { powerMonitors, selectedPowerMonDevice, deviceChanged } = useSelector(store => store.netmon);
  const [startdays, setStartDays] = useState('7');
  const [showModal, setShowModal] = useState(false);
  const [relaySettings, setRelaySettings] = useState({
    power_on: false,
    state: 'manual',
    on_temp: 0,
  });

  const fetchData = () => {
    let data = { deviceName: seldevice.device_name, duration: startdays };
    dispatch(getDevice(data));
  };

 /*  useEffect(() => {
    if (deviceChanged === true) {
      fetchData();
    }
  }, [deviceChanged]); */

  useEffect(() => {
    let data = { deviceName: seldevice.device_name, duration: startdays };
    fetchData();
    dispatch(getPowerMons(data));
    const interval = setInterval(() => {
      fetchData();
    }, 15000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [seldevice]);
  useEffect(() => {
    console.log("relaySettings", relaySettings);
  }, [relaySettings]);

  const handleFanClick = (relay) => {
    console.log('relay', relay);
    setRelaySettings(relay);
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRelaySettings({
      ...relaySettings,
      [name]: value,
    });
  };

  const handleSave = () => {
    console.log("relaySettings", relaySettings );
    dispatch(setRelay({ deviceName: seldevice.device_name, relay: relaySettings }));
    setShowModal(false);
  };

  return (
    <div>
      <Row>
        <Col className="col-md-2">
          <h6 style={{ color: 'white' }}>{selectedPowerMonDevice.name}</h6>
        </Col>
        <Col className="col-md-3">
          <h6 style={{ color: 'white' }}>{selectedPowerMonDevice.description}</h6>
        </Col>
      </Row>
      <Row>
      <Col className="col-md-2"/>
        <Col className="col-md-2">
          <div style={{ textAlign: 'center', color: 'white' }}>
            <GaugeComponent
              type="semicircle"
              arc={{
                width: 0.2,
                padding: 0.005,
                cornerRadius: 1,
                subArcs: [
                  {
                    limit: 80,
                    color: 'red',
                    showTick: true,
                    tooltip: {
                      text: 'Battery Too Low'
                    }
                  },
                  {
                    limit: 90,
                    color: '#F5CD19',
                    showTick: true,
                    tooltip: {
                      text: 'Warning: Low Battery'
                    }
                  },
                  {
                    color: 'green',
                    tooltip: {
                      text: 'Battery OK'
                    }
                  }
                ]
              }}
              pointer={{
                color: '#345243',
                length: 0.8,
                width: 15
              }}
              labels={{
                valueLabel: { formatTextValue: value => value + '%' },
                tickLabels: {
                  type: 'outer',
                  valueConfig: { formatTextValue: value => value + '%', fontSize: 10 },
                  ticks: [
                    { value: 60 },
                    { value: 50 }
                  ]
                }
              }}
              value={selectedPowerMonDevice.powerMonCurrent.battery_percentage}
              minValue={40}
              maxValue={100}
            />
            <h8 style={{ textAlign: 'center', color: 'white' }}>Battery %</h8>
          </div>
        </Col>
        <Col className="col-md-2">
          <div style={{ textAlign: 'center', color: 'white' }}>
            <GaugeComponent
              type="semicircle"
              arc={{
                width: 0.2,
                padding: 0.005,
                cornerRadius: 1,
                subArcs: [
                  {
                    limit: 10,
                    color: 'red',
                    showTick: true,
                    tooltip: {
                      text: 'Voltage Too Low'
                    }
                  },
                  {
                    limit: 12,
                    color: '#F5CD19',
                    showTick: true,
                    tooltip: {
                      text: 'Warning: Low Voltage'
                    }
                  },
                  {
                    color: 'green',
                    tooltip: {
                      text: 'Voltage OK'
                    }
                  }
                ]
              }}
              pointer={{
                color: '#345243',
                length: 0.8,
                width: 15
              }}
              labels={{
                valueLabel: { formatTextValue: value => value + ' Volts' },
                tickLabels: {
                  type: 'outer',
                  valueConfig: {
                    formatTextValue: value => value + ' Volts',
                    fontSize: 10
                  },
                  ticks: [{ value: 13.2 }, { value: 14.8 }]
                }
              }}
              value={selectedPowerMonDevice.powerMonCurrent.battery_voltage}
              minValue={8}
              maxValue={16}
            />
            <h8 style={{ textAlign: 'center', color: 'white' }}>Battery Volts</h8>
          </div>
        </Col>
        <Col className="col-md-2">
          <div style={{ textAlign: 'center', color: 'white' }}>
            <GaugeComponent
              type="semicircle"
              arc={{
                width: 0.2,
                padding: 0.005,
                cornerRadius: 1,
                subArcs: [
                  {
                    color: 'blue',
                    tooltip: {
                      text: 'Current Generation in Amps'
                    }
                  }
                ]
              }}
              pointer={{
                color: '#345243',
                length: 0.8,
                width: 15
              }}
              labels={{
                valueLabel: { formatTextValue: value => value + ' Amps' },
                tickLabels: {
                  type: 'outer',
                  valueConfig: {
                    formatTextValue: value => value + ' Amps',
                    fontSize: 10
                  },
                  ticks: [{ value: 5 }, { value: 10 }, { value: 15 }]
                }
              }}
              value={selectedPowerMonDevice.powerMonCurrent.battery_current}
              minValue={0}
              maxValue={20}
            />
            <h6>Solar Current</h6>
          </div>
        </Col>
        <Col className="col-md-2">
          <div style={{ textAlign: 'center', color: 'white' }}>
            <GaugeComponent
              type="semicircle"
              arc={{
                width: 0.2,
                padding: 0.005,
                cornerRadius: 1,
                subArcs: [
                  {
                    color: 'lightgreen',
                    tooltip: {
                      text: 'Voltage OK'
                    }
                  }
                ]
              }}
              pointer={{
                color: '#345243',
                length: 0.8,
                width: 15
              }}
              labels={{
                valueLabel: { formatTextValue: value => value + ' Amp/hour' },
                tickLabels: {
                  type: 'outer',
                  valueConfig: {
                    formatTextValue: value => value + ' Amp/hour',
                    fontSize: 10
                  },
                  ticks: [
                    { value: 20 },
                    { value: 40 },
                    { value: 60 },
                    { value: 80 },
                    { value: 100 }
                  ]
                }
              }}
              value={selectedPowerMonDevice.powerMonCurrent.charging_amp_hours_today}
              minValue={0}
              maxValue={120}
            />
            <h8 style={{ textAlign: 'center', color: 'white' }}>Amp/hours</h8>
          </div>
        </Col>
        </Row>
      <Row>
      <Col className="col-md-2"/>
        <Col className="col-md-2">
          <div style={{ textAlign: 'center', color: 'white' }}>
            <GaugeComponent
              type="semicircle"
              arc={{
                width: 0.2,
                padding: 0.005,
                cornerRadius: 1,
                subArcs: [
                  {
                    limit: 95,
                    color: 'Green',
                    showTick: true,
                    tooltip: {
                      text: 'Temperature OK'
                    }
                  },
                  {
                    limit: 110,
                    color: 'yellow',
                    showTick: true,
                    tooltip: {
                      text: 'Warning: Temperature High'
                    }
                  },
                  {
                    color: 'red',
                    tooltip: {
                      text: 'Temperature Too High'
                    }
                  }
                ]
              }}
              pointer={{
                color: '#345243',
                length: 0.8,
                width: 15
              }}
              labels={{
                valueLabel: { formatTextValue: value => value + ' ºF' },
                tickLabels: {
                  type: 'outer',
                  valueConfig: {
                    formatTextValue: value => value + ' ºF',
                    fontSize: 10
                  },
                  ticks: [{ value: 50 }, { value: 70 }]
                }
              }}
              value={selectedPowerMonDevice.powerMonCurrent.controller_temperature}
              minValue={20}
              maxValue={130}
            />
            <h8 style={{ textAlign: 'center', color: 'white' }}>Controller Temp</h8>
          </div>
        </Col>
        <Col className="col-md-2">
                <div style={{ textAlign: 'center' }}>
                  <GaugeComponent
                    type="semicircle"
                    arc={{
                      width: 0.2,
                      padding: 0.005,
                      cornerRadius: 1,
                      subArcs: [
                        {
                          limit: 95,
                          color: 'Green',
                          showTick: true,
                          tooltip: {
                            text: 'Temperature OK'
                          }
                        },
                        {
                          limit: 110,
                          color: 'yellow',
                          showTick: true,
                          tooltip: {
                            text: 'Warning: Temperature High'
                          }
                        },
                        {
                          color: 'red',
                          tooltip: {
                            text: 'Temperature Too High'
                          }
                        }
                      ]
                    }}
                    pointer={{
                      color: '#345243',
                      length: 0.8,
                      width: 15
                    }}
                    labels={{
                      valueLabel: { formatTextValue: value => value + ' ºF' },
                      tickLabels: {
                        type: 'outer',
                        valueConfig: { formatTextValue: value => value + ' ºF', fontSize: 10 },
                        ticks: [
                          { value: 50 },
                          { value: 70 }
                        ]
                      }
                    }}
                    value={selectedPowerMonDevice.cabinet_temperature}
                    minValue={20}
                    maxValue={130}
                  />
                  <h8 style={{ textAlign: 'center' }}>Cabinet Temp</h8>
                </div>
              </Col>
        <Col className="col-md-2">
          <div style={{ margin: "10px", width: 120, height: 120, textAlign: "center" }}>
            {selectedPowerMonDevice.relay1.power_on ? (
              <div onClick={() => handleFanClick(selectedPowerMonDevice.relay1)}>
                <FanOn />
                <h6>Fan On</h6>
              </div>
            ) : (
              <div onClick={() => handleFanClick(selectedPowerMonDevice.relay1)}>
                <FanOff />
                <h6 style={{ paddingTop: "30px" }}>Fan Off</h6>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: '50px' }}>
        <Col className="col-md-6">
          <Card style={{ backgroundColor: 'black', color: 'white', border: '1px solid darkgray' }}>
            <CardHeader style={{ color: 'white', textAlign: 'center' }}>
              <h4>Battery Voltage</h4>
            </CardHeader>
            <CardBody>
              <div style={{ textAlign: 'center' }}>
                <PowerMonChart selectedData={'battery_voltage'} />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col className="col-md-6">
          <Card style={{ backgroundColor: 'black', color: 'white', border: '1px solid darkgray' }}>
            <CardHeader style={{ color: 'white', textAlign: 'center' }}>
              <h4>Battery Percentage</h4>
            </CardHeader>
            <CardBody>
              <div style={{ textAlign: 'center' }}>
                <PowerMonChart selectedData={'battery_percentage'} />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col className="col-md-6">
          <Card style={{ backgroundColor: 'black', color: 'white', border: '1px solid darkgray' }}>
            <CardHeader style={{ color: 'white', textAlign: 'center' }}>
              <h4>Solar Voltage</h4>
            </CardHeader>
            <CardBody>
              <div style={{ textAlign: 'center' }}>
                <PowerMonChart selectedData={'pv_voltage'} />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col className="col-md-6">
          <Card style={{ backgroundColor: 'black', color: 'white', border: '1px solid darkgray' }}>
            <CardHeader style={{ color: 'white', textAlign: 'center' }}>
              <h4>Controller Temperature</h4>
            </CardHeader>
            <CardBody>
              <div style={{ textAlign: 'center' }}>
                <PowerMonChart selectedData={'controller_temperature'} />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col className="col-md-6">
          <Card style={{ backgroundColor: 'black', color: 'white', border: '1px solid darkgray' }}>
            <CardHeader style={{ color: 'white', textAlign: 'center' }}>
              <h4>Solar Current</h4>
            </CardHeader>
            <CardBody>
              <div style={{ textAlign: 'center' }}>
                <PowerMonChart selectedData={'battery_current'} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <RelaySettingsModal
        showModal={showModal}
        setShowModal={setShowModal}
        relaySettings={relaySettings}
        handleInputChange={handleInputChange}
        handleSave={handleSave}
      />
    </div>
  );
};

export default PowerDeviceDetail;
